// TODO this file name should be changed and content can be optimized
export enum API_URLS {
  // Base
  API = "api/",
  BASE_SYSTEM_CONFIG = "api/SystemConfiguration/",
  BASE_USER = "api/user/",
  BASE_COURSE = "api/Course/",
  BASE_DOCUMENT = "api/Document/Upload/",
  BASE_DOCUMENT_LIST = "api/Document/",
  DASHBOARD = "api/dashboard/",
  BASE_HIGHSCHOOL = "api/highschool/",
  BASE_TERTIARY = "api/Tertiary/",
  BASE_SHOPPING = "api/Shopping/",
  BASE_PAYMENT = "api/Payment/",
  BASE_COMPANY_PROFILE = "api/CompanyProfile/",
  BASE_WORK_EXEPERIENCE = "api/WorkExperience/",
  BASE_REFERENCE = "api/Reference/",
  BASE_CLIENT = "api/Client/",
  BASE_SYSTEM_CONFIGURATION = "api/SystemConfiguration/",
  BASE_WORKFORCE_PROFILE = "api/WorkforceProfile/",
  BASE_TEAMS = "api/teams/",
  BASE_TEAMS_MEMBER = "api/TeamMember/",

  // common
  GET_CONFIG_DATE_ITEMS = "GetConfigDataItems",
  GET_OFO_CODE_ITEMS = "GetOFOCodeItems",

  // Course
  GET_AVAILABLE_COURSES = "GetAvailableCourses",
  GET_MY_COURSES = "GetMyCourses?courseStatusType=",
  GET_E_LEARNING = "GetElearning",
  DELETE_USER_COURSE = "DeleteUserCourse?userCourseKey=",
  ABANDON_COURSE = "AbandonCourse?userCourseKey=",
  EXTEND_COURSE_EXPIRY = "ExtendExpiry?userCourseKey=",
  UPDATE_RATING = "UpdateRating",
  UPDATE_PERSONAL_DEADLINE = "UpdatePersonalDeadline",
  START_COURSE_NAVIGATE_TO_MOODLE = "GetCourseUrl?courseId=",
  UPDATE_WISHLIST = "UpdateUserWishList?courseId=",
  GET_WISHLIST = "GetUserWishList",
  GET_TAG_LIST_FILTER = "GetCourseTagTypeList",
  GET_TYPE_LIST_FILTER = "GetTypeFilterList",
  GET_LEVEL_LIST_FILTER = "GetLevelFilterList",
  GET_PRICE_LIST_FILTER = "GetPriceFilter",
  GET_PRICE_RANGE_FILTER = "GetPriceRangeFilter",
  GET_DURATION_RANGE_FILTER = "GetDurationRangeFilter",
  GET_STORE_DATA = "GetStoreData",
  GET_COURSES_FROM_BUNDLE = "GetCourseBundle",
  GET_CERTIFICATE = "GetCertificate",

  // Common
  PAGESIZE = "&pageSize=",
  GETCONFIGDATAITEMS = "GetConfigDataItems",

  // Auth
  LOGIN = "login",
  LOGOUT = "logout",
  SIGNUP = "signup",
  REGISTER = "register",
  SENDOTP = "SendOtp",
  FORGETPASSWORD = "forgetpassword",
  CHANGEPASSWORD = "changepassword",
  VALIDATEOTP = "validateOtp",
  VALIDATE_SIGNUP_FORM = "ValidateRegister",
  RESET_PASSWORD = "resetPassword",
  HAND_SHAKE = "refreshtoken",
  CORPORATE_USER_INQUIRY = "Inquiry",
  VALIDATE_INQUARY = "validateInquiry",
  CREATE_PASSWORD_OTP = "CreatePasswordOTP",
  CREATE_PASSWORD = "createpassword",
  VALIDATE_TOKEN = "ValidateToken",

  // Dashboard
  GETNOTIFICATIONS = "GetNotifications?page=",
  MARK_NOTIFACTION_AS_READ = "MarkNotifactionAsRead/",
  GETMARKETINGLIST = "GetMarketingList?page=",
  GETLEADERLIST = "GetLeaderList?page=",
  GET_CLIENT_BROADCAST_LIST = "GetClientBroadcast?page=",
  GET_CLIENT_BANNERLIST = "GetClientBanner",

  // Other
  TERMSANDCONDITION = "termsandcondition/termsandcondition",
  PRIVACYPOLICY = "termsandcondition/privacypolicy",
  ABOUTUS = "termsandcondition/aboutus",
  GET_ABOUTUS = "GetAboutUs",
  GET_HEAR_ABOUT_US_LIST = "GetHearAboutUsList",
  GET_HELP_AND_SUPPORT = "GetHelpAndSupport",
  CREATE_HELP_AND_SUPPORT_REQUEST = "CreateHelpAndSupportRequest",
  GET_INDUSTRY_LIST = "GetIndustryList",

  // Profile
  USERDETAIL = "UserDetail",
  USERDOCUMENTS = "GetAllDocumentList",
  COUNTRY = "Country",
  GETLANGUAGELIST = "Language/GetLanguageList",
  CHANGEUSERPASSWORD = "changeUserPassword",
  CHANGEUSERNAME = "ChangeUsername",
  UPDATEPHONENO = "UpdatePhoneNo",
  DOWNLOADUSERDETAIL = "DownloadUserDetail",
  DEACTIVEPROFILE = "DeactiveProfile",
  SENDCHANGEEMAILOTP = "SendChangeEmailOTP",
  REACTIVATE = "reactivate",
  CHANGEEMAIL = "ChangeEmail",
  UPDATEPROFILE = "UpdateProfile",
  UPDATEFULLPROFILE = "updateUserProfile",
  GETDISABILITYLIST = "disability/GetDisabilityList",
  GETDISABILITYTYPELIST = "disability/GetDisabilityTypeList",
  GETDEMOGRAPHICLIST = "GetDemographicList",
  UPDATE_USER_SELECTION = "api/user/updateUserSelection",
  GET_GENDER_LIST = "GetGenderList",
  GET_MIGRATION_CHECK = "GetMigrationCheck",
  MIGRATE_USER_DATA = "MigrateStudent",
  GET_OFO_GROUP_DETAIL = "GetOFOGroupDetail?OFOCode=",
  SEND_CHANGE_REQUEST_MAIL = "SendChangeRequestMail",

  // High School
  DELETE_HIGHSCHOOL = "highschool/DeleteUserHighSchool?userHighSchoolKey=",
  SAVEHIGHSCHOOL = "highschool/save",
  HIGHSCHOOLLIST = "highschool/GetUserHighSchoolList",
  MY_HIGH_SCHOOL = "GetUserHighSchoolList",
  HIGHSCHOOL = "highschool",
  GRADELIST = "/GradeList",
  SUBJECTLIST = "/SubjectList",

  // Tertiary API
  MY_TERTIART_QUALIFICATION_LIST = "GetUserTertiaryList",
  ADD_TERTIART_QUALIFICATION = "Save",
  EDIT_TERTIART_QUALIFICATION = "update",
  DELETE_TERTIART_QUALIFICATION = "DeleteUserTertiary?userTertiaryKey=",
  GET_INSTITUDE_LIST = "GetTertiaryInstitutionList",
  GET_TYPE_OF_INSTITUTE_LIST = "GetTertiaryTypeList",
  GET_QUALIFICATION_LIST = "GetTertiaryQualificationList",
  GET_TYPE_OF_QUALIFICATION_LIST = "GetTertiaryQualificationTypeList",
  GET_TERTIARY_STATUS_LIST = "GetTertiaryCompleteTypeList",

  // Completed course API
  GET_COURSE_DETAIL = "GetCourseDetail?courseKey=",
  GET_USER_COURSE_LIST = "GetUserCourseList",
  ADD_USER_COURSE_LIST = "Save",
  DELETE_USER_COURSE_LIST = "DeleteUserCourse?userCourseKey=",
  GET_TRAINING_PROVIDER_LIST = "GetTrainingProviderList",

  // Skills API
  SAVE_SKILL = "skill/Save",
  DELETE_SKILL = "Skill/DeleteUserSkill?skillId=",
  GET_SKILL_LIST = "skill",
  GET_PROFFICIANCY_LEVEL_LIST = "skill/GetProfficiancyLevelList",
  GET_USER_SKILL_LIST = "skill/GetUserSkillList",

  // Work experience API
  GET_USER_WORK_EXPERIENCE_LIST = "GetUserWorkExperienceList",
  SAVE_WORK_EXPERIENCE = "Save",
  EDIT_WORK_EXPERIENCE = "Save",
  DELETE_WORK_EXPERIENCE = "DeleteUserWorkExperience?userWorkExperienceKey=",
  GET_USER_COMPANY_LIST = "GetUserCompany",
  GET_USER_REFERENCE_LIST = "GetUserReferenceList",
  SAVE_REFERENCE = "Save",
  EDIT_REFERENCE = "Save",
  DELETE_REFERENCE = "DeleteUserReference?userReferenceKey=",
  GET_JOB_TITLE_LIST = "GetJobTitle",
  GET_EMPLOYMENT_TYPE = "GetEmploymentType",
  GET_GROUP_DATA_LIST = "GetWorkExperienceGroup",
  GET_LOCATION_LIST = "GetLocation",
  GET_COMPANY_LIST = "GetUserCompany",

  GET_DOWNLOADAPP = "CreateDownloadAppRequest?email=",

  // Shopping
  GET_SHOPPING_CART = "GetShoppingCart",
  ADD_TO_CART = "Addtocart",
  DELETE_ITEM = "DeleteItem?userCartId=",
  CLEAR_CART = "ClearCart",
  GET_CART_DETAILS = "GetCartDetails",
  SELECT_CART_ITEM = "selectCartItem",

  // Billing
  GET_PAYMENT_HISTORY = "GetPaymentHistory",
  MAKE_PAYMENT_TRANSACTION = "makePaymentTransaction",
  SAVE_TRANSECTION_DETAIL = "SaveTransactionDetail",
  GET_INVOICE = "GetCourseInvoice",

  // Company profile
  ADD_COMPANY_PROFILE = "CreateAndUpdateCompanyProfile",
  GET_COMPANY_PROFILE = "GetCompanyProfile",
  GET_WORK_FORCE_PROFILE_USERS = "getWorkForceProfileUsers",
  GET_STAFF_COUNT_BY_EMPLOYMENT_TYPE = "GetStaffCountByEmploymentType",
  GET_WORK_FORCE_PROFILE_USERS_BY_ID = "GetWorkForceProfileUsersById",
  GET_INDUSTRY_AMENDMENTS = "GetIndustryAmendments",

  // Document
  GET_All_DOCUMENT_LIST = "GetAllDocumentsGroupByList",
  DELETE_DOCUMENT = "DeleteDocument",
  DownloadAll = "DownloadAll",

  // Workforce Profile
  CreateWorkForceProfile = "CreateWorkForceProfile",
  UpdateWorkForceProfile = "UpdateWorkForceProfile",
  SendMailToEmployee = "sendMailToEmployee",
  UpdateTerminationStatus = "UpdateTerminationStatus",
  GetWorkForceUsersCourseDetails = "GetWorkForceUsersCourseDetails",

  BasicBulkUpload = "BasicBulkUpload",
  AdvancedBulkUpload = "AdvancedBulkUpload",
  GetUserDetailsByEmail = "GetUserDetailsByEmail",
  GetProvinces = "GetProvinces",
  GETLIST = "GetList",
  DownloadWorkforceProfileExcel = "DownloadWorkforceProfileExcel",

  // theme
  THEME = "api/CompanyThemeSettings/get",
  THEME_UPDATE = "api/CompanyThemeSettings/update",
  THEME_RESET = "api/CompanyThemeSettings/reset",

  // Teams
  VALIDATE_USER_BEFORE_FETCHING_TEAM_DETAIL_ = "api/Teams/Validate",
  VALIDATE_MANAGE_TEAM_LINK_IN_HEADER = "api/Teams/ValidateManageTeamLink",
  GET_TEAMS_AVAILABLE_COURSES = "api/Teams/GetTeamsAvailableCourses",
  GET_TEAMS_ASSIGNED_COURSES = "api/Teams/GetAssignedCourseByTeamMember",
  GET_COURSE_LIST_BY_MEMBER = "api/TeamMember/GetTeamMemberCourses",
  TEAM_OVERVIEW = "api/Teams/TeamOverview",
  REMOVE_TEAM_MEMBER = "api/TeamMember/Delete",
  UNASSIGN_COURSE = "api/Teams/UnAssignCourse",
  UPDATE_COURSE_DEADLINE = "api/Teams/UpdateDeadLine",
  TRANSFER = "api/Teams/Transfer",
  EXTEND_DEADLINE_REQUEST = "api/Teams/ExtendDeadLineRequest",
  SEND_MESSAGE_TO_LEADS = "api/Teams/SendMessageToLeads",
  SEND_MESSAGE_TO_MEMBERS = "api/Teams/SendMessageToMembers",
  GET_AVAILABLE_COURSE_LIST = "courses",
  GET_TEAM_MEMBERS = "GetTeamMembers",
  GET_TEAMS = "GetTeams",
  GET_TEAM_BY_ID = "GetTeamById",
  CREATE = "Create",

  //Team Member
  GET_TEAM_MEMBERS_ROLES = "GetTeamMembersRoles",

  UPDATE = "Update",
  DELETE = "Delete",
  GET_TEAM_MEMBERS_TO_ASSIGN = "GetTeamMembersToAssign",
  ASSIGN_COURSE = "AssignCourse",
  GET_TEAM_MEMBER_ROLES_PERMISSION = "GetTeamMemberRolesPermission",
  GET_USERS_FOR_NON_TEAM_MEMBERS = "GetUsersForNonTeamMembers",
  GET_USERS_FOR_TRANSFER_TEAMS = "GetUsersForTransferTeams",

  // Assignment of dashboard
  GET_ASSIGNMENT = "GetReportAssignment",
  ADD_DASHBOARD_TO_ROLE = "AddReportAssignment",

  // Email subscribe of dashboard
  GET_EMAIL_SUBSCRIPTION = "GetReportTimeSchedule",
  SET_EMAIL_SUBSCRIPTION = "AddReportTimeSchedule",
}

export enum HTTP_STATUS_CODES {
  UNAUTHORIZED = 401,
}
