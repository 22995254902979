import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import { MyProfileServiceProvider } from "./modules/features/MyProfile/services/MyProfile.services";
import { CourseServiceProvider } from "./modules/features/MyCourses/services/Course.services";
import { DownloadAppServiceProvider } from "./modules/features/DownloadApp/services/DownloadApp.services";
import { HelpAndSupportServiceProvider } from "./modules/features/HelpAndSupport/services/HelpAndSupport.services";
import { UploadServiceProvider } from "./modules/core/services/FileUpload.services";
import { AboutUsServiceProvider } from "./modules/features/AboutUs/services/AboutUs.services";
import { BillingServiceProvider } from "./modules/features/BillingDetails/services/Billing.services";
import { ROUTES_CONST } from "./modules/core/models/constants/core.constants";
import { DashboardServiceProvider } from "./modules/features/dashboard/services/Dashboard.services";
import ProtectRoute from "./modules/auth/ProtectRoute";
import { CORPORATE_USER_TYPE } from "./modules/core/models/enums/tap.enum";
import { MORE_MENU_OPTIONS } from "./modules/features/layouts/presentation/ECHeader/menu.helper";

const Auth = lazy(() => import("./modules/auth/Auth"));
const Home = lazy(() => import("./modules/features/Home"));
const App = lazy(() => import("./App"));
const SignUpPage = lazy(() => import("./modules/auth/pages/SignUp/SignUpPage"));
const LogInPage = lazy(
  () => import("./modules/auth/pages/LogInPage/LogInPage")
);
const ForgetPasswordPage = lazy(
  () => import("./modules/auth/pages/ForgetPasswordPage")
);
const CreatePasswordPage = lazy(
  () => import("./modules/auth/pages/CreatePasswordPage")
);
const DashbordPage = lazy(
  () => import("./modules/features/dashboard/pages/DashbordPage")
);
const PageNotFound = lazy(
  () => import("./modules/core/presentation/pageNotFound")
);
const Public = lazy(() => import("./modules/core/presentation/Public"));
const ComingSoon = lazy(
  () => import("./modules/auth/presentation/ComingSoon/ComingSoon")
);
const ContactUsPage = lazy(() => import("./modules/auth/pages/ContactUsPage"));
const AboutUs = lazy(() => import("./modules/features/AboutUs"));
const ContactUs = lazy(() => import("./modules/features/ContactUs"));
const HelpAndSupport = lazy(() => import("./modules/features/HelpAndSupport"));
const MyProfile = lazy(() => import("./modules/features/MyProfile"));
const MyShoppingCart = lazy(() => import("./modules/features/MyShoppingCart"));
const DownloadApp = lazy(
  () => import("./modules/features/DownloadApp/pages/DownloadAppPage")
);
const BillingDetailsPage = lazy(
  () => import("./modules/features/BillingDetails/pages/BillingDetailsPage")
);
const Protected = lazy(() => import("./modules/core/presentation/Protected"));
const MySettingsPage = lazy(
  () => import("./modules/features/MyProfile/pages/MySettingsPage")
);
const ReactivatePage = lazy(
  () => import("./modules/auth/pages/ReactivatePage")
);
const MyCoursesDetail = lazy(
  () =>
    import(
      "./modules/features/MyCourses/presentation/MyCoursesDetail/MyCoursesDetail"
    )
);
const TeamListPage = lazy(
  () => import("./modules/features/Teams/pages/TeamsListPage")
);
const TeamDetailPage = lazy(
  () => import("./modules/features/Teams/pages/TeamDetailPage")
);
const MyCoursesPage = lazy(() => import("./modules/features/MyCourses"));
const WishListPage = lazy(
  () => import("./modules/features/MyCourses/pages/WishListPage")
);
const StorePage = lazy(
  () => import("./modules/features/MyCourses/pages/StorePage")
);
const MyShoppingCartPage = lazy(
  () => import("./modules/features/MyShoppingCart")
);
const Checkout = lazy(
  () => import("./modules/features/MyShoppingCart/pages/CheckoutPage")
);
const PaymentSuccessPage = lazy(
  () => import("./modules/features/MyCourses/pages/PaymentSuccessPage")
);
const PaymentCancelPage = lazy(
  () => import("./modules/features/MyCourses/pages/PaymentCancelPage")
);
const BundleDetailPage = lazy(
  () => import("./modules/features/MyCourses/pages/BundleDetailPage")
);
const WorkforceProfile = lazy(
  () => import("./modules/features/WorkforceProfile")
);
const DashboardReportingPage = lazy(
  () =>
    import("./modules/features/DashboardReporting/pages/DashboardReportingPage")
);

const CompanyProfile = lazy(() => import("./modules/features/CompanyProfile"));

const BulkUploadPage = lazy(
  () => import("./modules/features/WorkforceProfile/pages/BulkUploadPage")
);

const IndividualEmployeeAddPage = lazy(
  () =>
    import("./modules/features/WorkforceProfile/pages/IndividualEmployeeAdd")
);

const ThemePage = lazy(() => import("./modules/features/Theme"));

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: ROUTES_CONST.ROOT,
        element: (
          <Public>
            <Auth />
          </Public>
        ),
        children: [
          {
            path: ROUTES_CONST.LOGIN,
            element: (
              <MyProfileServiceProvider>
                <LogInPage />
              </MyProfileServiceProvider>
            ),
            index: true,
          },
          {
            path: ROUTES_CONST.SIGNUP,
            element: <SignUpPage />,
          },
          {
            path: ROUTES_CONST.FORGET_PASSWORD,
            element: <ForgetPasswordPage />,
          },
          {
            path: ROUTES_CONST.CREATE_PASSWORD,
            element: <CreatePasswordPage />,
          },
          {
            path: ROUTES_CONST.REACTIVATE,
            element: <ReactivatePage />,
          },
          {
            path: ROUTES_CONST.CONTACT_US,
            element: <ContactUsPage />,
          },
          {
            path: ROUTES_CONST.ASTRIC_SYMBOL,
            element: <PageNotFound />,
          },
        ],
      },
      {
        path: ROUTES_CONST.HOME,
        element: (
          <Protected>
            <MyProfileServiceProvider>
              <CourseServiceProvider>
                <Home />
              </CourseServiceProvider>
            </MyProfileServiceProvider>
          </Protected>
        ),
        children: [
          {
            path: ROUTES_CONST.DASHBOARD,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <DashboardServiceProvider>
                  <DashbordPage />
                </DashboardServiceProvider>
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.COMINGSOON,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <ComingSoon />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.ABOUTUS,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <AboutUsServiceProvider>
                  <AboutUs />
                </AboutUsServiceProvider>
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.CONTACTUS,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <ContactUs />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.HELPANDSUPPROT,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <HelpAndSupportServiceProvider>
                  <HelpAndSupport />
                </HelpAndSupportServiceProvider>
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.MYCOURSES,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <MyCoursesPage />
              </ProtectRoute>
            ),
            children: [
              {
                path: ":id",
                element: (
                  <ProtectRoute
                    allowedToIndividualUser={true}
                    allowedToCorporateUser={true}
                    allowedRoles={[
                      CORPORATE_USER_TYPE.ADMIN,
                      CORPORATE_USER_TYPE.HR,
                      CORPORATE_USER_TYPE.EMPLOYEE,
                    ]}
                  >
                    <MyCoursesDetail />
                  </ProtectRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES_CONST.MYPROFILE,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <UploadServiceProvider>
                  <MyProfile />
                </UploadServiceProvider>
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.MYSETTING,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <MySettingsPage />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.MYSHOPINGCART,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={false}
                allowedRoles={[]}
              >
                <MyShoppingCart />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.BILLINGDETAILS,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={false}
                allowedRoles={[]}
              >
                <BillingServiceProvider>
                  <BillingDetailsPage />
                </BillingServiceProvider>
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.STORE,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <StorePage />
              </ProtectRoute>
            ),
          },
          {
            path: `${ROUTES_CONST.BUNDLE}/:id`,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <BundleDetailPage />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.WISHLIST,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={false}
                allowedRoles={[]}
              >
                <WishListPage />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.MYSHOPINGCART,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={false}
                allowedRoles={[]}
              >
                <MyShoppingCartPage />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.CHECKOUT,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={false}
                allowedRoles={[]}
              >
                <Checkout />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.PAYMENT_SUCCESS,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={false}
                allowedRoles={[]}
              >
                <PaymentSuccessPage />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.PAYMENT_CANCEL,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={false}
                allowedRoles={[]}
              >
                <PaymentCancelPage />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.DASHBOARDREPORTING,
            element: (
              <ProtectRoute
                allowedToIndividualUser={false}
                allowedToCorporateUser={true}
                allowedRoles={[CORPORATE_USER_TYPE.ADMIN, CORPORATE_USER_TYPE.HR]}
              >
                <DashboardReportingPage />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.TEAMS,
            element: (
              <ProtectRoute
                allowedToIndividualUser={false}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <TeamListPage myTeams={true} />
              </ProtectRoute>
            ),
            children: [
              {
                path: ":id",
                element: (
                  <ProtectRoute
                    allowedToIndividualUser={false}
                    allowedToCorporateUser={true}
                    allowedRoles={[
                      CORPORATE_USER_TYPE.ADMIN,
                      CORPORATE_USER_TYPE.HR,
                      CORPORATE_USER_TYPE.EMPLOYEE,
                    ]}
                  >
                    <TeamDetailPage myTeams={true} />
                  </ProtectRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES_CONST.MANAGE_TEAMS,
            element: (
              <ProtectRoute
                allowedToIndividualUser={false}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
                pageURL={MORE_MENU_OPTIONS.MANAGE_TEAMS}
              >
                <TeamListPage myTeams={false} />
              </ProtectRoute>
            ),
            children: [
              {
                path: ":id",
                element: (
                  <ProtectRoute
                    allowedToIndividualUser={false}
                    allowedToCorporateUser={true}
                    allowedRoles={[
                      CORPORATE_USER_TYPE.ADMIN,
                      CORPORATE_USER_TYPE.HR,
                      CORPORATE_USER_TYPE.EMPLOYEE,
                    ]}
                    pageURL={MORE_MENU_OPTIONS.MANAGE_TEAMS}
                  >
                    <TeamDetailPage myTeams={false} />
                  </ProtectRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES_CONST.COMPANYPROFILE,
            element: (
              <ProtectRoute
                allowedToIndividualUser={false}
                allowedToCorporateUser={true}
                allowedRoles={[CORPORATE_USER_TYPE.ADMIN]}
              >
                <UploadServiceProvider>
                  <CompanyProfile />
                </UploadServiceProvider>
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.DOWNLOADAPP,
            element: (
              <ProtectRoute
                allowedToIndividualUser={true}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN,
                  CORPORATE_USER_TYPE.HR,
                  CORPORATE_USER_TYPE.EMPLOYEE,
                ]}
              >
                <DownloadAppServiceProvider>
                  <DownloadApp />
                </DownloadAppServiceProvider>
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.WORKFORCE_PROFILE,
            element: (
              <ProtectRoute
                allowedToIndividualUser={false}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN, CORPORATE_USER_TYPE.HR
                ]}
              >
                <WorkforceProfile />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.WORKFORCE_PROFILE_BULK_UPLOAD,
            element: (
              <ProtectRoute
                allowedToIndividualUser={false}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN, CORPORATE_USER_TYPE.HR
                ]}
              >
                <BulkUploadPage />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.WORKFORCE_PROFILE_INDIVIDUAL_EMPLOYEE_ADD,
            element: (
              <ProtectRoute
                allowedToIndividualUser={false}
                allowedToCorporateUser={true}
                allowedRoles={[
                  CORPORATE_USER_TYPE.ADMIN, CORPORATE_USER_TYPE.HR
                ]}
              >
                <IndividualEmployeeAddPage />
              </ProtectRoute>
            ),
          },
          {
            path: ROUTES_CONST.THEME,
            element: (
              <ProtectRoute
                allowedToIndividualUser={false}
                allowedToCorporateUser={true}
                allowedRoles={[CORPORATE_USER_TYPE.ADMIN]}
              >
                <ThemePage />
              </ProtectRoute>
            ),
          },
        ],
      },
    ],
  },
]);
export default router;
