export class CoreConstants {
  static readonly APP_SECRET_KEY = "";
}

export const SOUTH_AFRICA_CUSTOM_COUNTRY_CODE = 163;

export const DATE_TIME_CONSTANTS = {
  LONG_DATE_TIME: "ddd, DD MMM YYYY",
  SC_LONG_DATE_TIME: "D MM YYYY h:mm A",
  SC_SHORT_MONTH_YEAR: "MMM YYYY",
  SC_LONG_DO_DATES: "D of MMMM YYYY",
  DATE_FORMAT: "YYYY-MM-DD",
  ONLY_YEAR: "YYYY",
};
export const SC_BUTTON_TYPE = {
  PRIMARY: "primary",
  EXPIRED: "expired",
  SECONDARY: "secondary",
  SUBMIT_PRIMARY: "submit_primary",
  UPLOADBUTTON: "uploadButton",
  SUBMIT_UPLOADBUTTON: "submit_uploadButton",
  KEYPAD: "keypad",
  TYPELINK: "TypeLink",
  CLEAR_FORM: "clear_form",
  PRICING_BACK: "pricing_back",
  UPLOAD_FILE_LABEL: "Upload Evidence",
};

export const VALIDATION_CONST = {
  Password_minimumLength: 8,
};
export const INPUT_ALLOWED_KEYS = [
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Shift",
  "Alt",
  "Control",
  "End",
  "Home",
];
export const NOTIFICATION_CONST = {
  SUCCESS: "success",
  ERROR: "error",
  NETWORK_ERROR: "network",
};
export const ROUTES_CONST = {
  ROOT: "/",
  LOGIN: "login",
  SIGNUP: "signup",
  COMINGSOON: "coming-soon",
  FORGET_PASSWORD: "forget-password",
  CREATE_PASSWORD: "create-password",
  REACTIVATE: "reactivate",
  CONTACT_US: "contact",
  HOME_BASE: "/home",
  HOME: "/home/",
  DASHBOARD: "dashboard",
  DASHBOARDREPORTING: "dashboard-reporting",
  MAPREPORTING: "map-reporting",
  TEAMS: "my-teams",
  MANAGE_TEAMS: "manage-teams",
  ABOUTUS: "aboutus",
  CONTACTUS: "contactus",
  HELPANDSUPPROT: "help-and-support",
  MYCOURSES: "my-courses",
  MYPROFILE: "my-profile",
  COMPANYPROFILE: "company-profile",
  MYSETTING: "my-setting",
  MYSHOPINGCART: "shoping-cart",
  CHECKOUT: "checkout",
  FEATURES: "features",
  DOWNLOADAPP: "download-app",
  BILLINGDETAILS: "billing-details",
  STORE: "store",
  BUNDLE: "bundle",
  WISHLIST: "wishlist",
  PAYMENT_SUCCESS: "PaymentSuccess",
  PAYMENT_CANCEL: "PaymentCancel",
  ASTRIC_SYMBOL: "*",
  ASTRIC_SYMBOL_HOME: "/home/*",
  WORKFORCE_PROFILE: "workforce-profile",
  WORKFORCE_PROFILE_BULK_UPLOAD: "workforce-profile/bulk-upload",
  WORKFORCE_PROFILE_INDIVIDUAL_EMPLOYEE_ADD:
    "workforce-profile/individual-employee-add",
  THEME: "theme",
};
export const CALCULATION_CONST = {
  KB: 1024,
  MB: 1048576,
};

export const PAGINATION_SETTING = {
  PAGE_SIZE: 10,
};

export const DOCUMENT_TYPES = {
  CURRICULUM_VITAE: "Curriculum Vitae",
  IDENTITY_DOCUMENT: "Identity Document",
  DRIVING_LICENSE_DOCUMENT: "Driving License Document",
  RESIDENT_DOCUMENT: "Resident Document",
  TERTIARY_QUALIFICATION: "Tertiary Qualification",
  PROFILE_PICTURE: "Profile Picture",
  HIGH_SCHOOL_CERTIFICATE: "High School Certificate",
  OTHER_COURSE: "Other Course",
  WORK_EXPERIENCE: "Work Experience",
  DISABLED_PROOF: "Disabled Proof",
  DTI_REGISTRATION_CERTIFICATE: "DTI Registration Certificate",
  TAX_CLEARANCE_CERTIFICATE: "Tax Clearance Certificate",
  CONFIRMATION_BANKING_DETAILS: "Confirmation of Banking Details",
  BBBEE_CERTIFICATE: "B-BBEE Certificate",
  SDF_LETTER_OF_APPOINTMENT: "SDF Letter of Appointment",
  SKILLS_EQUITY_COMMITTEE_MEETING_AGENDA:
    "Skills and Equity Committee Meeting Agenda & Minutes",
  EMP201: "EMP201",
};

export type AutoSuggestType = {
  value: string;
  label: string;
  id: number;
};

export type SelectDropdownType = {
  value: number;
  label: string;
};
